


































import { Booking } from '@/models';
import { BookingNote } from '@/models/BookingNote';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';

export default defineComponent({
  name: 'PatientBookingQuickView',
  props: {
    booking: {
      type: Object as PropType<Booking>,
      required: true,
    },
  },

  setup(props) {
    const notes = ref<BookingNote[]>([]);

    const fetchNotes = async () => {
      notes.value = await props.booking.notes().get();
    };

    watch(
      () => props.booking,
      () => fetchNotes(),
      { immediate: true }
    );

    return {
      notes,
    };
  },
});
