



























import { Booking, Patient } from '@/models';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';
import { debouncedSearch } from '@/utils/inputSearch';
import { routeNames } from '@/router/routeNames';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'PatientBookingNotesSearch',
  props: {
    patient: {
      type: Object as PropType<Patient>,
      required: true,
    },
  },
  setup(props) {
    const bookings = ref<Booking[]>([]);
    const search = ref<string | null>(null);
    const loading = ref(false);

    const { router } = useRouter();

    const fetchBookings = async (): Promise<void> => {
      loading.value = true;
      bookings.value = [...(await props.patient.bookings().where('notes', search.value).get())];
      loading.value = false;
    };

    const searchBookings = debouncedSearch(() => fetchBookings());

    const getMarkedNotes = (item: Booking) => {
      if (!search.value || !item) {
        return;
      }
      const string = item.data.notes[0].notes;
      const first = string.indexOf(search.value);
      const last = string.lastIndexOf(search.value);
      const limit = 255;

      var start = 0;
      var end = limit;

      if (first == last) {
        // There is only one occurence
        // The first occurence is greater than the limit
        if (first > limit) {
          // Make the first and only occurence appear in the middle of the string
          start = first - limit;
          end = first + string.length;
        }
      } else {
        // There is more than once occurence
        if (last > limit) {
          // The last occurence is greater than the limit
          start = first;
          end = first + limit;
        }
      }

      const trimmed = string.substring(start, end);

      const marked = trimmed.replaceAll(search.value, `<span class="v-list-item__mask">${search.value}</span>`);
      return marked;
    };

    const goToBooking = (item: Booking) => {
      router.push({ name: routeNames.practitioner.bookings.view, params: { uuid: item.uuid } });
    };

    watch(search, () => searchBookings());

    return {
      bookings,
      loading,
      search,
      getMarkedNotes,
      goToBooking,
    };
  },
});
