var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.bookings,"headers":_vm.headers,"options":_vm.options,"footer-props":{
      itemsPerPageOptions: [5, 10, 25, 50, 100],
    },"server-items-length":_vm.total,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"py-2",attrs:{"flat":""}},[_c('div',{staticClass:"flex flex-grow-1"},[(_vm.patient)?_c('patient-booking-notes-search',{attrs:{"patient":_vm.patient}}):_vm._e(),_c('v-divider',{staticClass:"my-2"})],1)])]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"label":"","color":item.getStatusColour()}},[_vm._v(_vm._s(_vm._f("sentence")(item.data.status)))])]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startTime,"EEEE',' do 'of' MMMM',' yyyy"))+" ")]}},{key:"item.time",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.startTime,'h:mma'))+" to "+_vm._s(_vm._f("formatDate")(item.endTime,'h:mma'))+" ")]}},{key:"item.duration",fn:function(ref){
    var item = ref.item;
return [_vm._v(_vm._s(item.data.duration)+" minutes")]}},{key:"item.practitioner",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.practitioner.name)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","color":"primary","to":{ name: _vm.routeNames.practitioner.bookings.view, params: { uuid: item.uuid } }}},[_vm._v("View")]),_c('v-btn',{attrs:{"small":"","color":"info mr-1"},on:{"click":function($event){_vm.quickViewing = item}}},[_vm._v("Quick View")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('div',[_c('span',[_c('strong',[_vm._v("Updated: ")]),_vm._v(" "+_vm._s(item.updatedAt))]),_c('br'),_c('span',[_c('strong',[_vm._v("Created: ")]),_vm._v(" "+_vm._s(item.createdAt))])])])]}}],null,true)}),(_vm.quickViewing)?_c('v-dialog',{attrs:{"value":true,"max-width":"600px"},on:{"input":function($event){_vm.quickViewing = null}}},[_c('patient-booking-quick-view',{attrs:{"booking":_vm.quickViewing}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }