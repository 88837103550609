




































































import PatientBookingNotesSearch from '@/components/inputs/PatientBookingNotesSearch.vue';
import PatientBookingQuickView from './PatientBookingQuickView.vue';
import { Booking, Patient } from '@/models';
import { PropType, defineComponent, ref, watch } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';

export default defineComponent({
  components: { PatientBookingQuickView, PatientBookingNotesSearch },
  name: 'PatientBookingsTable',
  props: {
    patient: {
      type: Object as PropType<Patient | null>,
    },
    order: {
      type: String,
      required: false,
      default: 'latest',
    },
    exclude: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const loading = ref(true);
    const bookings = ref<Booking[]>([]);
    const quickViewing = ref<Booking | null>(null);

    const total = ref(0);

    const options = ref({
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      page: 1,
      itemsPerPage: 25,
      sortBy: ['date'],
      sortDesc: [props.order === 'latest' ? false : true],
    });

    const headers = [
      // { text: 'Patient', value: 'patient' },
      { text: 'Status', value: 'status' },
      { text: 'Date', value: 'date', sortable: true },
      { text: 'Time', value: 'time', sortable: false },
      { text: 'Duration', value: 'duration', sortable: false },
      { text: 'Practitioner', value: 'practitioner', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false },
    ];

    const fetchBookings = async () => {
      loading.value = true;
      if (!props.patient) {
        return;
      }

      let { data, meta } = await props.patient
        .bookings()
        .with('practitioner', 'bookingReasons')
        .sortBy(options.value.sortBy[0], options.value.sortDesc[0])
        .paginate(options.value.page, options.value.itemsPerPage);

      bookings.value = props.exclude ? data.filter((item: Booking) => item.uuid != props.exclude) : data;
      total.value = meta.total;
      loading.value = false;
    };

    watch([() => props.patient, options], () => fetchBookings());

    return {
      loading,
      bookings,
      total,
      options,
      routeNames: routeNames,
      headers,
      quickViewing,
    };
  },
});
