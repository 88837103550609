









import PatientBookingsTable from '../../components/PatientBookingsTable.vue';
import ProfileTile from '../../../shared/components/ProfileTile.vue';
import { Patient } from '@/models';
import { defineComponent, onMounted, ref } from '@/plugins/composition';
import { useRouter } from '@/router';

export default defineComponent({
  components: { ProfileTile, PatientBookingsTable },
  name: 'PatientBookings',

  setup() {
    const patient = ref<Patient | null>();

    const { route } = useRouter();

    onMounted(async () => {
      patient.value = await Patient.findOrFail(route.value.params.uuid);
    });

    return {
      patient,
    };
  },
});
