export const debouncedSearch = <T extends () => void>(callable: T): ((gate?: boolean, timeout?: number) => void) => {
  let timer: number | undefined;
  let queued: T | undefined;
  return (gate = true, timeout = 1000) => {
    if (!gate) {
      return;
    }

    if (!timer) {
      callable();
    } else {
      queued = callable;
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      timer = undefined;
      if (queued) {
        queued();
        queued = undefined;
      }
    }, timeout) as unknown as number;
  };
};
